import React, {useState} from 'react';
import brand from './values/brand'
import modelsByBrand from './values/modelsByBrand'
import typeOfBike from './values/typeOfBike'
import {Github} from "react-color/lib/components/github/Github";
import Select from 'react-select';
import sizes from "./values/sizes";
import defaultValues from "./values/defaultValues";
import AddressLookupMap from "./map/AddressLookup";
import eventBus from "../utils/EventBus";
import EventTypes from "../utils/EventTypes";


function SearchFilters() {
    const [filterList, setFilterList] = useState({});
    const [selectedBrand, setBrand] = useState([]);
    const [selectedColor, setColor] = useState({hex: '#000'});
    const handleSelectBrand = (selectedOption) => {
        defineBrand(selectedOption)
    };

    const defineBrand = (indexToSet) => {
        if (typeof indexToSet != 'undefined' && typeof modelsByBrand[indexToSet.value] != 'undefined') {
            setBrand((modelsByBrand[indexToSet.value]));
        } else {
            setBrand([]);
        }
    };

    const applyFilter = () => {
        eventBus.emit(EventTypes.FILTER_SEARCH_ADDED, {tags: Object.keys(filterList)});
    };

    const updateFilter = (filterValue, filterId) => {
        console.log(filterList);
        if (typeof filterValue === 'undefined') {
            delete filterList[filterId];
        } else {
            filterList[filterId] = filterValue;
        }
        setFilterList(filterList)
    };

    return (
        <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvas" aria-labelledby="search-filter">
            <div className="offcanvas-header">
                <h5 className="offcanvas-title text-center" id="search-filter">
                    <i className="fa fa-cogs fs-2" aria-hidden="true"></i>
                    &nbsp;Filter
                </h5>
                <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                        aria-label="Close"></button>
            </div>
            <div className="offcanvas-body">
                <div className="mb-3">
                    <label htmlFor="serial-number" className="form-label">Serial number</label>
                    <input type="text" className="form-control rounded" id="serial-number"
                           placeholder="Enter serial number" onfocusout={e => {
                        updateFilter(e.target.value().trim(), "serial-number")
                    }}/>
                </div>
                <div className="mb-3">
                    <label htmlFor="reportNumberFilter" className="form-label">Police Report Number</label>
                    <input type="text" className="form-control rounded" id="reportNumberFilter"
                           placeholder="Enter report number" onfocusout={e => {
                        updateFilter(e.target.value().trim(), "reportNumberFilter")
                    }}/>
                </div>
                {/*<div className="mb-3">*/}
                {/*    <AddressLookupMap subtitle={true} mapConfig={{*/}
                {/*        show: true,*/}
                {/*        isEditable: true,*/}
                {/*        height: "200px",*/}
                {/*        useMarker: false,*/}
                {/*        zoom: 13*/}
                {/*    }}/>*/}
                {/*</div>*/}
                <div className="mb-3">
                    <label htmlFor="brandFilter" className="form-label">Brand</label>
                    <Select
                        defaultValue={defaultValues}
                        options={brand} onChange={e => {
                        handleSelectBrand(e);
                        updateFilter(e.value, "brand");
                    }}/>
                </div>
                <div className="mb-3">
                    <label htmlFor="modelFilter" className="form-label">Enter bike model</label>
                    <Select
                        defaultValue={defaultValues}
                        options={selectedBrand}
                        onInputChange={e => updateFilter(e.value, "modelFilter")}/>
                </div>
                <div className="mb-3">
                    <label htmlFor="typeFilter" className="form-label">Type of Bike</label>
                    <Select
                        defaultValue={defaultValues}
                        options={typeOfBike}
                        onInputChange={e => updateFilter(e.value, "typeFilter")}/>
                </div>
                <div className="mb-3">
                    <label htmlFor="sizeFilter" className="form-label">Bike Size</label>
                    <Select
                        defaultValue={defaultValues}
                        options={sizes}
                        onInputChange={e => updateFilter(e.value, "sizeFilter")}
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="colorFilter" className="form-label">Color</label>
                    <Github
                        color={selectedColor}
                        colors={[
                            '#FF0000', '#00FF00', '#0000FF', '#FFFF00', '#FF00FF',
                            '#00FFFF', '#000000', '#FFFFFF', '#808080', '#800000'
                        ]}
                        triangle={'hide'}
                        onChange={(newColor) => {
                            setColor(newColor.hex);
                            updateFilter(newColor.hex,"colorFilter");
                        }}
                    />
                </div>

                <div className="mb-3">
                    <label htmlFor="dateFilter" className="form-label">Date of Theft</label>
                    <input type="date" className="form-control rounded" id="dateFilter" onChange={e => {
                        updateFilter(e.target.value.trim(),"dateFilter");
                    }}/>
                </div>

                <div className="mb-3">
                    <label htmlFor="accessoriesFilter" className="form-label">Accessories/Additional Features</label>
                    <select multiple className="form-select" id="accessoriesFilter"  onChange={e => {
                        updateFilter(e.target.value.trim(),"accessoriesFilter");
                    }}>
                        <option value="basket">Basket</option>
                        <option value="lights">Lights</option>
                        <option value="gps">GPS Tracker</option>
                        <option value="racks">Racks</option>
                        <option value="fenders">Fenders</option>
                        <option value="bell">Bell</option>
                        <option value="saddlebag">Saddlebag</option>
                    </select>
                    <small className="form-text text-muted">Hold Ctrl (or Cmd on Mac) to select multiple
                        features.</small>
                </div>
                {/*<div className="mb-3">*/}
                {/*    <label htmlFor="location" className="form-label">Location</label>*/}
                {/*    <div className="col-md-12">*/}
                {/*        <label htmlFor="city" className="form-label">City</label>*/}
                {/*        <input type="text" className="form-control rounded" id="city" placeholder="Enter city" required />*/}
                {/*        <div className="invalid-feedback">Please provide a valid city.</div>*/}
                {/*    </div>*/}
                {/*    <div className="col-md-12 mt-3">*/}
                {/*        <label htmlFor="state" className="form-label">State</label>*/}
                {/*        <select className="form-select rounded" id="state" required>*/}
                {/*            <option selected disabled value="">Choose...</option>*/}
                {/*            <option>...</option>*/}
                {/*        </select>*/}
                {/*        <div className="invalid-feedback">Please select a valid state.</div>*/}
                {/*    </div>*/}
                {/*    <div className="col-md-12 mt-3">*/}
                {/*        <label htmlFor="zip" className="form-label">Zip</label>*/}
                {/*        <input type="text" className="form-control rounded" id="zip" placeholder="Enter zip code" required />*/}
                {/*        <div className="invalid-feedback">Please provide a valid zip.</div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className="d-flex justify-content-end pt-3 border-top">
                    <button type="button" className="btn btn-secondary me-2" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" className="btn btn-save" onClick={applyFilter}>Apply</button>
                </div>
            </div>
        </div>
    );
}

export default SearchFilters;
