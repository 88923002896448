import React, {useEffect, useState} from 'react';
import AddressLookupMap from "../components/map/AddressLookup";
import eventBus from "../utils/EventBus";
import EventTypes from "../utils/EventTypes";
import SearchFilters from "../components/SearchFilters";

function Home() {

    const [isDisplayMainContent, displayMainContent] = useState("block");

    useEffect(() => {
        const openMap = (data) => {
            displayMainContent(data.isOpen ? "none" : "block");
        };
        eventBus.on(EventTypes.MAP_CLICK, openMap);
        return () => {
            eventBus.off(EventTypes.MAP_CLICK, openMap);
        };
    }, []);

    const settingsOpts = () => {
        return (
            <div style={{display: "inline-flex"}}>
                <button
                    className="btn btn-outline-secondary"
                    type="button"
                    aria-expanded="false"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvas"
                    aria-controls="offcanvas"
                >
                    <i className="fa fa-search" aria-hidden="true"></i>
                </button>
                <button
                    className="btn btn-outline-secondary"
                    type="button"
                    aria-expanded="false"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvas"
                    aria-controls="offcanvas"
                >
                    <i className="fa fa-sliders" aria-hidden="true"></i>
                </button>
            </div>);
    };


    return (
        <section id={"body-container"} className=""
                 style={{display: isDisplayMainContent}}>
            <SearchFilters/>
            <div className={"map-controls-overlay"}>
                <AddressLookupMap optionsBtns={settingsOpts()} mapConfig={{
                    show: true,
                    isEditable: false,
                    height: "93vh",
                    useMarker: true,
                    zoom: 16
                }}/>
            </div>
        </section>
    );
}

export default Home;
