export default [
    {"value": "trek", "label": "Trek"},
    {"value": "specialized", "label": "Specialized"},
    {"value": "giant", "label": "Giant"},
    {"value": "cannondale", "label": "Cannondale"},
    {"value": "santa_cruz", "label": "Santa Cruz"},
    {"value": "yeti_cycles", "label": "Yeti Cycles"},
    {"value": "pivot_cycles", "label": "Pivot Cycles"},
    {"value": "scott", "label": "Scott"},
    {"value": "rocky_mountain", "label": "Rocky Mountain"},
    {"value": "kona", "label": "Kona"},
    {"value": "norco", "label": "Norco"},
    {"value": "intense_cycles", "label": "Intense Cycles"},
    {"value": "gt_bicycles", "label": "GT Bicycles"},
    {"value": "orbea", "label": "Orbea"},
    {"value": "merida", "label": "Merida"},
    {"value": "ibis_cycles", "label": "Ibis Cycles"},
    {"value": "canyon", "label": "Canyon"},
    {"value": "mondraker", "label": "Mondraker"},
    {"value": "cube", "label": "Cube"},
    {"value": "commencal", "label": "Commencal"},
    {"value": "lapierre", "label": "Lapierre"},
    {"value": "nukeproof", "label": "Nukeproof"},
    {"value": "devinci", "label": "Devinci"},
    {"value": "felt_bicycles", "label": "Felt Bicycles"},
    {"value": "evil_bikes", "label": "Evil Bikes"},
    {"value": "transition_bikes", "label": "Transition Bikes"},
    {"value": "salsa_cycles", "label": "Salsa Cycles"},
    {"value": "whyte", "label": "Whyte"},
    {"value": "bianchi", "label": "Bianchi"},
    {"value": "marin", "label": "Marin"},
    {"value": "diamondback", "label": "Diamondback"},
    {"value": "bmc", "label": "BMC"},
    {"value": "polygon", "label": "Polygon"},
    {"value": "yt_industries", "label": "YT Industries"},
    {"value": "saracen", "label": "Saracen"},
    {"value": "orange_bikes", "label": "Orange Bikes"},
    {"value": "ghost_bikes", "label": "Ghost Bikes"},
    {"value": "mongoose", "label": "Mongoose"},
    {"value": "liv", "label": "Liv"},
    {"value": "juliana_bicycles", "label": "Juliana Bicycles"},
    {"value": "fuji", "label": "Fuji"},
    {"value": "ns_bikes", "label": "NS Bikes"},
    {"value": "khs_bicycles", "label": "KHS Bicycles"},
    {"value": "revel_bikes", "label": "Revel Bikes"},
    {"value": "ribble_cycles", "label": "Ribble Cycles"},
    {"value": "propain_bikes", "label": "Propain Bikes"},
    {"value": "sunn", "label": "Sunn"},
    {"value": "haibike", "label": "Haibike"},
    {"value": "unno", "label": "Unno"},
    {"value": "alchemy_bicycles", "label": "Alchemy Bicycles"},
    {"value": "3t", "label": "3T"},
    {"value": "fezzari", "label": "Fezzari"},
    {"value": "kinesis", "label": "Kinesis"},
    {"value": "radon_bikes", "label": "Radon Bikes"},
    {"value": "rose_bikes", "label": "Rose Bikes"},
    {"value": "cotic", "label": "Cotic"},
    {"value": "banshee", "label": "Banshee"},
    {"value": "lynskey", "label": "Lynskey"},
    {"value": "liteville", "label": "Liteville"},
    {"value": "rotwild", "label": "Rotwild"},
    {"value": "guerrilla_gravity", "label": "Guerrilla Gravity"},
    {"value": "niner", "label": "Niner"},
    {"value": "allied_cycle_works", "label": "Allied Cycle Works"},
    {"value": "bold_cycles", "label": "Bold Cycles"},
    {"value": "knolly_bikes", "label": "Knolly Bikes"},
    {"value": "spot", "label": "Spot"},
    {"value": "factor_bikes", "label": "Factor Bikes"},
    {"value": "boardman_bikes", "label": "Boardman Bikes"},
    {"value": "ellsworth", "label": "Ellsworth"},
    {"value": "s-works", "label": "S-Works"},
    {"value": "ritchey", "label": "Ritchey"}
]