
// Custom hook for managing geolocation
import {useEffect, useState} from "react";
import {BROWSER_LATITUDE, BROWSER_LONGITUDE, resolveCurrentLocation} from "../utilities/locationResolver";

// Constants
const initialPosition = [37.7749, -122.4194]; // San Francisco

export function resolveGeoLocation(latitudeProp , longitudeProp, setLatitude, setLongitude, setError) {
    if (latitudeProp && longitudeProp) return;
    const savedLatitude = parseFloat(localStorage.getItem(BROWSER_LATITUDE));
    const savedLongitude = parseFloat(localStorage.getItem(BROWSER_LONGITUDE));

    if (savedLatitude && savedLongitude) {
        setLatitude(savedLatitude);
        setLongitude(savedLongitude);
    } else {
        resolveCurrentLocation(setLatitude, setLongitude, setError);
    }
}

export const useGeolocation = (latitudeProp, longitudeProp) => {
    const [latitude, setLatitude] = useState(latitudeProp || initialPosition[0]);
    const [longitude, setLongitude] = useState(
        longitudeProp || initialPosition[1]
    );
    const [error, setError] = useState(null);

    useEffect(() => {
        resolveGeoLocation(latitudeProp, longitudeProp, setLatitude, setLongitude, setError);
    }, [latitudeProp, longitudeProp]);

    return { latitude, longitude, error };
};
