import React, { useState, useCallback } from 'react';
import 'leaflet/dist/leaflet.css';
import { milesToMeters} from "../../utilities/utils";
import {DraggableCircle, RadiusControl} from "./helper";
import {useMap, useMapEvent} from "react-leaflet";

const milesToDegreesLatitude = (miles) => miles / 69; // Approximate conversion for latitude (1 degree ≈ 69 miles)
const milesToDegreesLongitude = (miles, latitude) => {
    // Convert latitude from degrees to radians
    const latitudeInRadians = latitude * (Math.PI / 180);
    // Calculate degrees of longitude
    return miles / (69 * Math.cos(latitudeInRadians));
};

// Main Map with Radius Component
const MapWithRadius = ({ latitude, longitude, theRadius, onRadiusChange , onDragChange}) => {
    const [radius, setRadius] = useState(theRadius); // Default radius in miles
    const map = useMap();
    useMapEvent('drag', (map) => {
        if (onDragChange) {
            onDragChange(map.target.getCenter);
        }
    });
    const handleRadiusChange = useCallback((event) => {
        setRadius(event.target.value);
        // Calculate the bounds of the circle based on the new radius
        const radiusInMiles = event.target.value;
        const bounds = calculateBounds([latitude, longitude], radiusInMiles);
        map.fitBounds(bounds); // Adjust the map's zoom to fit the circle within the view

        if(onRadiusChange){
            onRadiusChange(event.target.value);
        }
    }, []);

    const calculateBounds = (center, radiusInMiles) => {
        const [lat, lng] = center;
        const latRadius = milesToDegreesLatitude(radiusInMiles); // Convert miles to degrees latitude
        const lngRadius = milesToDegreesLongitude(radiusInMiles, lat); // Convert miles to degrees longitude

        const northEast = [lat + latRadius, lng + lngRadius];
        const southWest = [lat - latRadius, lng - lngRadius];
        return [southWest, northEast];
    };


    if (latitude == null || longitude == null) {
        return null;
    }

    return (
        <div>
            <DraggableCircle
                radius={milesToMeters(radius)}
                latitude={latitude}
                longitude={longitude}
                key={radius.toString()}
            />
            <RadiusControl radius={radius} onRadiusChange={handleRadiusChange} />
        </div>
    );
};

export default MapWithRadius;
