// Custom hook to handle the map's center on drag
import React, {useState} from "react";
import {Circle, useMap, useMapEvent} from "react-leaflet";


// Draggable Circle Component
export const DraggableCircle = ({radius, latitude, longitude}) => {
    const [center, setCenter] = useState([latitude, longitude]);

    useMapEvent('drag', (map) => {
        setCenter(map && map.target.getCenter && map.target.getCenter());
    });

    if (!center) return null;
    return (
        <Circle
            key={center.toString()}
            center={center}
            radius={radius}
            color="rgba(0, 102, 255, 1)"
            fillColor="rgba(0, 102, 255, 0.3)"
            fillOpacity={0.3}
            weight={2}
            dashArray="5,5"
        />
    );
};

// Radius Control Component
export const RadiusControl = ({radius, onRadiusChange}) => {
    const map = useMap();

    const handleMouseDown = (e) => {
        e.stopPropagation();
        map.dragging.disable(); // Disable map dragging when interacting with slider
    };

    const handleMouseUp = () => {
        map.dragging.enable(); // Re-enable map dragging after interaction
    };

    return (
        <div className={"radius-control-slider"}
             style={{textAlign: 'center', bottom: 20, position: 'fixed', zIndex: '10000'}}>
            <label>Radius: {radius} mi</label>
            <input
                type="range"
                min="0.5"
                max="5"
                step={"0.5"}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onTouchStart={handleMouseDown}
                onTouchEnd={handleMouseUp}
                value={radius}
                onChange={onRadiusChange}
                style={{width: '80%', marginLeft: '10px'}}

            />
        </div>
    )
};
