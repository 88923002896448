
// Component for Feature Group with Edit Control
import {FeatureGroup, Marker} from "react-leaflet";
import {EditControl} from "react-leaflet-draw";
import React from "react";

export const FeatureGroupControl = ({ isEditable }) => {
    if (!isEditable) return null;

    return (
        <FeatureGroup>
            <EditControl
                position="topright"
                draw={{
                    rectangle: false,
                    circle: true,
                    circlemarker: false,
                    marker: false,
                    polyline: false,
                }}
            />
        </FeatureGroup>
    );
};

// Component for rendering a draggable marker
export const MarkerComponent = ({ position, useMarker, draggable }) => {
    if (!useMarker) return null;
    return <Marker position={position} draggable={draggable} />;
};
