const modelsByBrand = {
    trek: [
        {value: 'trek_fuel_ex', label: 'Trek Fuel EX'},
        {value: 'trek_marlin', label: 'Trek Marlin'},
        {value: 'trek_slash', label: 'Trek Slash'},
        {value: 'trek_remedy', label: 'Trek Remedy'},
        {value: 'trek_top_fuel', label: 'Trek Top Fuel'}
    ],
    specialized: [
        {value: 'specialized_stumpjumper', label: 'Specialized Stumpjumper'},
        {value: 'specialized_epic', label: 'Specialized Epic'},
        {value: 'specialized_enduro', label: 'Specialized Enduro'},
        {value: 'specialized_fuse', label: 'Specialized Fuse'},
        {value: 'specialized_rockhopper', label: 'Specialized Rockhopper'}
    ],
    giant: [
        {value: 'giant_trance', label: 'Giant Trance'},
        {value: 'giant_anthem', label: 'Giant Anthem'},
        {value: 'giant_reign', label: 'Giant Reign'},
        {value: 'giant_fathom', label: 'Giant Fathom'},
        {value: 'giant_talon', label: 'Giant Talon'}
    ],
    cannondale: [
        {value: 'cannondale_habit', label: 'Cannondale Habit'},
        {value: 'cannondale_scalpel', label: 'Cannondale Scalpel'},
        {value: 'cannondale_trail', label: 'Cannondale Trail'},
        {value: 'cannondale_jekyll', label: 'Cannondale Jekyll'},
        {value: 'cannondale_topstone', label: 'Cannondale Topstone'}
    ],
    santa_cruz: [
        {value: 'santa_cruz_hightower', label: 'Santa Cruz Hightower'},
        {value: 'santa_cruz_5010', label: 'Santa Cruz 5010'},
        {value: 'santa_cruz_bronson', label: 'Santa Cruz Bronson'},
        {value: 'santa_cruz_nomad', label: 'Santa Cruz Nomad'},
        {value: 'santa_cruz_tallboy', label: 'Santa Cruz Tallboy'}
    ],
    yeti_cycles: [
        {value: 'yeti_sb130', label: 'Yeti SB130'},
        {value: 'yeti_sb150', label: 'Yeti SB150'},
        {value: 'yeti_sb100', label: 'Yeti SB100'},
        {value: 'yeti_sb165', label: 'Yeti SB165'},
        {value: 'yeti_sb140', label: 'Yeti SB140'}
    ],
    pivot_cycles: [
        {value: 'pivot_mach_6', label: 'Pivot Mach 6'},
        {value: 'pivot_trail_429', label: 'Pivot Trail 429'},
        {value: 'pivot_switchblade', label: 'Pivot Switchblade'},
        {value: 'pivot_firebird', label: 'Pivot Firebird'},
        {value: 'pivot_mach_55', label: 'Pivot Mach 5.5'}
    ],
    scott: [
        {value: 'scott_spark', label: 'Scott Spark'},
        {value: 'scott_genius', label: 'Scott Genius'},
        {value: 'scott_scale', label: 'Scott Scale'},
        {value: 'scott_ransom', label: 'Scott Ransom'},
        {value: 'scott_aspect', label: 'Scott Aspect'}
    ],
    orbea: [
        {value: 'orbea_oiz', label: 'Orbea Oiz'},
        {value: 'orbea_occam', label: 'Orbea Occam'},
        {value: 'orbea_rallon', label: 'Orbea Rallon'},
        {value: 'orbea_alma', label: 'Orbea Alma'},
        {value: 'orbea_laufey', label: 'Orbea Laufey'}
    ],
    canyon: [
        {value: 'canyon_spectral', label: 'Canyon Spectral'},
        {value: 'canyon_neuron', label: 'Canyon Neuron'},
        {value: 'canyon_strive', label: 'Canyon Strive'},
        {value: 'canyon_lux', label: 'Canyon Lux'},
        {value: 'canyon_grand_canyon', label: 'Canyon Grand Canyon'}
    ],
    ibis_cycles: [
        {value: 'ibis_ripmo', label: 'Ibis Ripmo'},
        {value: 'ibis_mojo', label: 'Ibis Mojo'},
        {value: 'ibis_ripley', label: 'Ibis Ripley'},
        {value: 'ibis_hd5', label: 'Ibis HD5'},
        {value: 'ibis_dv9', label: 'Ibis DV9'}
    ],
    norco: [
        {value: 'norco_sight', label: 'Norco Sight'},
        {value: 'norco_optic', label: 'Norco Optic'},
        {value: 'norco_fluid', label: 'Norco Fluid'},
        {value: 'norco_range', label: 'Norco Range'},
        {value: 'norco_revolver', label: 'Norco Revolver'}
    ],
    rocky_mountain: [
        {value: 'rocky_mountain_altitude', label: 'Rocky Mountain Altitude'},
        {value: 'rocky_mountain_slayer', label: 'Rocky Mountain Slayer'},
        {value: 'rocky_mountain_element', label: 'Rocky Mountain Element'},
        {value: 'rocky_mountain_instinct', label: 'Rocky Mountain Instinct'},
        {value: 'rocky_mountain_growler', label: 'Rocky Mountain Growler'}
    ],
    gt_bicycles: [
        {value: 'gt_force', label: 'GT Force'},
        {value: 'gt_sensor', label: 'GT Sensor'},
        {value: 'gt_zaskar', label: 'GT Zaskar'},
        {value: 'gt_avalanche', label: 'GT Avalanche'},
        {value: 'gt_grade', label: 'GT Grade'}
    ],
    kona: [
        {value: 'kona_process', label: 'Kona Process'},
        {value: 'kona_hei_hei', label: 'Kona Hei Hei'},
        {value: 'kona_honzo', label: 'Kona Honzo'},
        {value: 'kona_sutra', label: 'Kona Sutra'},
        {value: 'kona_big_honzo', label: 'Kona Big Honzo'}
    ],
    marin: [
        {value: 'marin_rift_zone', label: 'Marin Rift Zone'},
        {value: 'marin_hawk_hill', label: 'Marin Hawk Hill'},
        {value: 'marin_alpine_trail', label: 'Marin Alpine Trail'},
        {value: 'marin_pine_mountain', label: 'Marin Pine Mountain'},
        {value: 'marin_san_quentin', label: 'Marin San Quentin'}
    ],
    diamondback: [
        {value: 'diamondback_release', label: 'Diamondback Release'},
        {value: 'diamondback_sync_r', label: 'Diamondback Sync\'r'},
        {value: 'diamondback_atroz', label: 'Diamondback Atroz'},
        {value: 'diamondback_mission', label: 'Diamondback Mission'},
        {value: 'diamondback_overdrive', label: 'Diamondback Overdrive'}
    ],
    bmc: [
        {value: 'bmc_fourstroke', label: 'BMC Fourstroke'},
        {value: 'bmc_speedfox', label: 'BMC Speedfox'},
        {value: 'bmc_twostroke', label: 'BMC Twostroke'},
        {value: 'bmc_trailfox', label: 'BMC Trailfox'},
        {value: 'bmc_agonist', label: 'BMC Agonist'}
    ]
};

export default modelsByBrand