import React from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const RadiusChangeToast = ({ title, onConfirm, onCancel }) => {
    return (
        <div>
            <p>{title}</p>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                <button
                    style={{ marginRight: '10px' }}
                    onClick={() => {
                        toast.dismiss();
                        onCancel();
                    }}
                >
                    Cancel
                </button>
                <button
                    onClick={() => {
                        toast.dismiss();
                        onConfirm();
                    }}
                >
                    Confirm
                </button>
            </div>
        </div>
    )
};