import React from 'react';

function NavbarMenu() {
    const environment = process.env.REACT_APP_ENV;

    const showMetaInfo = () => {
        if (environment && environment === "PROD") {
            return (<li className={"meta-info"}>
                <h6 className={"production"}> {process.env.REACT_APP_VERSION} - {process.env.REACT_APP_MESSAGE}</h6>
            </li>);
        }
        return null;
    };

    const showUserMenu = ()=>{
        if (environment && environment === "PROD") {
            return null;
        }
            return (

            <div className="dropdown">
                <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
                        data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="fa fa-bars" aria-hidden="true"></i>
                </button>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <li><a className="dropdown-item" href="#">Action</a></li>
                    <li><a className="dropdown-item" href="#">Another action</a></li>
                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                </ul>
            </div>
        );
    };
    return (
        <nav className="navbar navbar-expand-lg navbar-light">
            <ul className="nav w-100">
                <li className="nav-item ps-5">
                    <img src="/img/logo.png" alt="bicicletasrobadas.com" height={"60px"}/>
                </li>
                {showMetaInfo()}
                {showUserMenu()}
                <li className="nav-item">
                    <a href="#" className="nav-link active">
                        <i className="fa fa-user-circle-o login-icon" aria-hidden="true"></i>
                    </a>
                </li>
            </ul>
        </nav>
    );
}

export default NavbarMenu;
