export const BROWSER_LATITUDE = 'latitudeWebBrowser';
export const BROWSER_LONGITUDE = 'longitudeWebBrowser';

export const resolveCurrentLocation = (setLatitude, setLongitude, setError) => {
    if (!navigator.geolocation) {
        setError('Geolocation is not supported by this browser.');
        return;
    }

    const watchId = navigator.geolocation.watchPosition(
        (position) => {
            const { latitude, longitude } = position.coords;
            setLatitude(latitude);
            setLongitude(longitude);
            localStorage.setItem(BROWSER_LATITUDE, latitude);
            localStorage.setItem(BROWSER_LONGITUDE, longitude);
        },
        (error) => setError(error.message)
    );

    return () => {
        navigator.geolocation.clearWatch(watchId);
    };
};
