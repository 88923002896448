import React from 'react';
import {
    MapContainer,
    TileLayer,
    LayersControl, useMapEvent,
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import 'leaflet-draw/dist/leaflet.draw.css';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';
import {useGeolocation} from "./hooks/GeoLocation";
import {LayerSelector} from "./hooks/LayerSelector";
import {FeatureGroupControl, MarkerComponent} from "./hooks/Helper";
import MapWithRadius from "./controls/distanceRadiusSlider/distanceRadiusSlider";
// Constants
const {BaseLayer, Overlay} = LayersControl;

// Set default marker icon
L.Marker.prototype.options.icon = L.icon({
    iconUrl: markerIcon,
    shadowUrl: markerShadow,
});

// Main Map Component
const MyMap = ({
                   height,
                   width,
                   isEditable,
                   show,
                   useMarker,
                   draggableMarker,
                   zoom,
                   latitude: latitudeProp,
                   longitude: longitudeProp,
                   children,
                   radius,
                   onRadiusChange,
                   onDragChange
               }) => {
    const {latitude, longitude} = useGeolocation(latitudeProp, longitudeProp);


    if (!show) return null;

    const radiusElem = () => {
        if (!radius) return null;
        return (<MapWithRadius longitude={longitude} latitude={latitude} theRadius={radius}
                               onRadiusChange={onRadiusChange} onDragChange={onDragChange}/>);
    };
    return (
        <MapContainer
            center={[latitude, longitude]}
            zoom={zoom}
            key={[latitude, longitude].toString()}
            style={{height, width}}
        >
            {children}
            <FeatureGroupControl isEditable={isEditable}/>
            <MarkerComponent
                position={[latitude, longitude]}
                useMarker={useMarker}
                draggable={draggableMarker}
            />
            <LayersControl position="topleft">
                <BaseLayer checked name="OpenStreetMap">
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution="&copy; OpenStreetMap contributors"
                    />
                </BaseLayer>
                <BaseLayer name="Satellite">
                    <TileLayer
                        url="http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
                        attribution="&copy; Google"
                        subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                    />
                </BaseLayer>
                <BaseLayer name="CartoDB Positron">
                    <TileLayer
                        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                        attribution="&copy; CARTO"
                    />
                </BaseLayer>
            </LayersControl>
            <LayerSelector/>
            {radiusElem()}
        </MapContainer>
    );
};

MyMap.defaultProps = {
    height: '400px',
    width: '100%',
    isEditable: false,
    show: false,
    useMarker: false,
    draggableMarker: false,
    zoom: 13,
};

export default MyMap;
