import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';

function RecenterLocation({onClick}) {
    const map = useMap(); // Get the map instance from react-leaflet

    useEffect(() => {
        // Create a new Leaflet Control
        const customControl = L.control({ position: 'topleft' });

        // Define what happens when the control is added to the map
        customControl.onAdd = function(map) {
            const div = L.DomUtil.create('div', 'leaflet-touch leaflet-bar map-recenter-location');
            div.innerHTML = '<a class="leaflet-control-zoom-in"> <i style="font-size: 16px" class=" fa fa-crosshairs"></i></a>';
            // Optional: add event listener to your custom control
            div.addEventListener('click',onClick);
            return div;
        };

        // Add the control to the map
        customControl.addTo(map);

        // Cleanup function to remove the control when the component unmounts
        return () => {
            customControl.remove();
        };
    }, [map]);

    return null;
}

export default RecenterLocation;
