

// Component for Layer Selection
import {useEffect, useState} from "react";
import {useMapEvents} from "react-leaflet";

export const LayerSelector = () => {
    const [selectedLayer, setSelectedLayer] = useState(null);

    useMapEvents({
        baselayerchange: (event) => setSelectedLayer(event.name),
    });

    useEffect(() => {
        if (selectedLayer) {
            console.log(`Selected Layer: ${selectedLayer}`);
        }
    }, [selectedLayer]);

    return null;
};
