import './App.css';
import React, {useEffect, useState} from 'react';
import NavbarMenu from "./components/Navbar";
import Home from "./content/Home";

function App() {

    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    return (
        <div className="App container-fluid-md container-fluid-xl container-fluid-xxl container-fluid ps-0 pe-0">
           <div className="row g-0">
               <div className="col-12">
                   <header className="App-header">
                       <NavbarMenu/>
                   </header>
               </div>
               <div className="col-12">
                   <Home/>
               </div>
           </div>
            {/*<footer className="mt-sm-5 mt-md-5 mt-xs-5 mt-lg-5">*/}
            {/*    <div id="bottom-footer" className="fixed-bottom">*/}
            {/*        bicicletarobada.com*/}
            {/*    </div>*/}
            {/*</footer>*/}
        </div>
    );
}

export default App;
