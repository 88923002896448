import React, {useState} from 'react';
import 'leaflet/dist/leaflet.css';
import MyMap from "./AddressMap";
import RecenterLocation from "./controls/recenter";
import {toast, ToastContainer} from "react-toastify";
import {RadiusChangeToast} from "../notifications/RadiusChangeToast";
import {resolveGeoLocation, useGeolocation} from "./hooks/GeoLocation";
import 'react-toastify/dist/ReactToastify.css';

const AddressLookupMap = (props) => {
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [address, setAddress] = useState('');
    const toastId = React.useRef(null);

    const TriggerReCenter = () => {
       resolveGeoLocation(null, null, setLatitude, setLongitude, null);
    };
    const triggerReSearchAction = (title) => {
        return  (newRadius) => {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast(
                    <RadiusChangeToast
                        title={`Do you want to search in this new ${title}?`}
                        onConfirm={() => handleSearch(newRadius)}
                        onCancel={() => console.log('Search canceled')}
                    />,
                    {autoClose: true, toastId: toastId.current}
                );
            }
        };
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {


            handleSearch();
        }
    };

    const handleSearch = () => {
        fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(address)}`)
            .then(response => response.json())
            .then(data => {
                if (data.length > 0) {
                    const {lat, lon} = data[0];
                    setLatitude(parseFloat(lat));
                    setLongitude(parseFloat(lon));
                } else {
                    alert('Address not found');
                }
            });
    };

    if (!props.mapConfig.show) {
        return null;
    }

    return (
        <div className={"Map-Box"} style={{width: "100%"}}>
            <label style={{display: props.subtitle ? "block" : "none"}} htmlFor="locationFilter"
                   className="form-label">Location <span style={{color: 'rgb(200, 200, 200)', fontSize: "0.8em"}}>Draw a circle to search in that area</span></label>
            <div className={"map-lookup-address"}>
                <input type="text" className="form-control locationFilter-input" style={{borderRadius: "0px"}}
                       id="locationFilter"
                       value={address}
                       onChange={(e) => setAddress(e.target.value)}
                       onKeyDown={handleKeyDown}
                       placeholder="Enter city or zip then press enter"/>
                {props.optionsBtns}
            </div>
            <div className={"map-container"}>
                <MyMap show={props.mapConfig.show} latitude={latitude} longitude={longitude}
                       isEditable={props.mapConfig.isEditable} height={props.mapConfig.height}
                       key={[latitude, longitude].toString()}
                       radius={"0.2"}
                       useMarker={props.mapConfig.useMarker} draggableMarker={props.mapConfig.draggableMarker}
                       zoom={props.mapConfig.zoom} onRadiusChange={triggerReSearchAction("radius")} onDragChange={triggerReSearchAction("location")}>
                    <RecenterLocation onClick={TriggerReCenter}/>
                    <ToastContainer/>
                </MyMap>
            </div>
        </div>
    );
};

export default AddressLookupMap;