export default [
    {"value": "mountain_bike", "label": "Mountain Bike (MTB)"},
    {"value": "road_bike", "label": "Road Bike"},
    {"value": "hybrid_bike", "label": "Hybrid Bike"},
    {"value": "gravel_bike", "label": "Gravel Bike"},
    {"value": "cyclocross_bike", "label": "Cyclocross Bike"},
    {"value": "bmx_bike", "label": "BMX Bike"},
    {"value": "fat_bike", "label": "Fat Bike"},
    {"value": "electric_mountain_bike", "label": "Electric Mountain Bike (e-MTB)"},
    {"value": "downhill_bike", "label": "Downhill Bike"},
    {"value": "enduro_bike", "label": "Enduro Bike"},
    {"value": "trail_bike", "label": "Trail Bike"},
    {"value": "cross_country_bike", "label": "Cross Country (XC) Bike"},
    {"value": "freeride_bike", "label": "Freeride Bike"},
    {"value": "all_mountain_bike", "label": "All-Mountain Bike"},
    {"value": "dirt_jump_bike", "label": "Dirt Jump Bike"},
    {"value": "urban_bike", "label": "Urban Bike"},
    {"value": "folding_bike", "label": "Folding Bike"},
    {"value": "touring_bike", "label": "Touring Bike"},
    {"value": "single_speed_bike", "label": "Single-Speed Bike"},
    {"value": "fixie_bike", "label": "Fixie Bike"},
    {"value": "cruiser_bike", "label": "Cruiser Bike"},
    {"value": "track_bike", "label": "Track Bike"},
    {"value": "city_bike", "label": "City Bike"},
    {"value": "tandem_bike", "label": "Tandem Bike"},
    {"value": "recumbent_bike", "label": "Recumbent Bike"},
    {"value": "cargo_bike", "label": "Cargo Bike"},
    {"value": "kids_bike", "label": "Kids Bike"},
    {"value": "fitness_bike", "label": "Fitness Bike"},
    {"value": "commuter_bike", "label": "Commuter Bike"},
    {"value": "recumbent_trike", "label": "Recumbent Trike"},
    {"value": "triathlon_tt_bike", "label": "Triathlon/TT Bike"},
    {"value": "randonneur_bike", "label": "Randonneur Bike"},
    {"value": "bikepacking_bike", "label": "Bikepacking Bike"},
    {"value": "step_through_bike", "label": "Step-Through Bike"},
    {"value": "beach_cruiser", "label": "Beach Cruiser"},
    {"value": "electric_road_bike", "label": "Electric Road Bike (e-Road)"},
    {"value": "electric_hybrid_bike", "label": "Electric Hybrid Bike (e-Hybrid)"},
    {"value": "electric_fat_bike", "label": "Electric Fat Bike (e-Fat Bike)"}
]